import { Link } from "react-router-dom";
import "../assets/scss/components/menu.scss";
import resume from "../assets/files/Karine_CV_ Frontend.pdf";

export function Menu(props) {

   const { handleMenu } = props;


   return (
      <div className="menu__wrap">
         <div className="menu__backdrop" onClick={handleMenu}></div>
         <div className="menu">
            <div className="menu__container">
               <ul className="menu__nav">
                  <li>
                     <Link className="menu__link" onClick={handleMenu} to="/about">about</Link>
                  </li>
                  <li>
                     <Link className="menu__link" onClick={handleMenu} to="/projects">projects</Link>
                  </li>
                  <li>
                     <a className="menu__link" onClick={handleMenu} href={resume} target="_blank" rel="noreferrer">resume</a>
                  </li>
               </ul>

               <div className="menu__contact">
                  <p>karine@yakistudio.com</p>
                  <p>+351 91258 3405</p>
               </div>
            </div>
         </div>
      </div>
   );
}